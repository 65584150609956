@mixin mobile {
    @media only screen and(max-width: 768px) {
        @content;
    }
}
.wrapper {
    display: grid;
    grid-gap: 36px;
    min-height: 100vh;
    padding: 120px var(--default-layout-header-height) var(--default-layout-header-height);
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    background: var(--image-gradient), url(../../assets/images/bg_vangiau.png) no-repeat top center fixed;

    @include mobile {
        padding: 0;
        grid-template-columns: 1fr;
    }
}

.content {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0 20px;
    .heading {
        font-size: 3.5rem;
    }
    .about-description {
        font-size: 2rem;
        font-weight: 400;
        margin: 20px 0;
    }
    .about-image {
        width: auto;
        height: auto;
        border-radius: 20px;
        display: grid;
        place-items: center;
        background: linear-gradient(45deg, transparent, #38250b, transparent);

        img {
            width: 80%;
            border-radius: inherit;
            overflow: hidden;
            @include mobile {
                width: 50%;
            }
        }
    }
    .hobby {
        padding: 20px 0 20px 20px;
    }
}

.container {
    width: 100%;
    background: var(--primary);
    padding: 20px 0;

    .heading {
        text-align: center;
    }
    .skills {
        display: grid;
        grid-gap: 10px;
        padding: 0 40px;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

        &-item {
            border: 1.7px solid rgba(200, 137, 230, 0.637);
            border-radius: 5px;
            box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.5);
            display: table;
            font-size: 4.5rem;
            margin: 15px;
            opacity: 0.93;
            overflow: hidden;
            padding: 10px;
            text-align: center;
            transition: all 0.4s ease 0s;
            vertical-align: middle;
            &:hover {
                border: 2.2px solid rgba(197, 115, 230, 0.883);
                overflow: hidden;
                -webkit-transform: scale(1.05);
                transform: scale(1.05);
                transition-delay: 0.3s;
            }
        }
    }
    .github-calendar {
        display: flex;
        justify-content: center;
        @include mobile {
            padding: 20px;
        }
    }
}
/* Experience.module.scss */

.container {
    width: 100%;
    background: var(--primary);
    padding: 20px 0;

    .heading {
        text-align: center;
    }

    .experience-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .experience-item {
            text-align: center;
            margin: 20px;

            .company-logo {
                /* CSS cho logo công ty */
                width: 100px; /* Ví dụ: đặt chiều rộng là 100px */
                height: 100px; /* Ví dụ: đặt chiều cao là 100px */
                margin-right: 20px; /* Ví dụ: đặt khoảng cách bên phải */
                /* Thêm các thuộc tính CSS khác tùy theo yêu cầu của bạn */

                /* Bổ sung thuộc tính CSS mới cho `.company-logo` */
                border: 1px solid #ccc; /* Đặt viền cho logo */
                border-radius: 10px; /* Đặt góc bo cho logo */
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Đặt shadow cho logo */
                transition: transform 0.2s ease-in-out; /* Hiệu ứng thay đổi kích thước khi hover */
                cursor: pointer; /* Hiển thị biểu tượng con trỏ khi hover */

                &:hover {
                    transform: scale(1.1); /* Phóng to logo khi hover */
                }
            }

            .company-name {
                /* CSS cho tên công ty */
                font-weight: bold;
                margin-top: 10px;
            }
        }
    }
}

