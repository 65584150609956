.wrapper {
    background: #0a0416;
    display: flex;
    width: 100%;
    height: var(--default-layout-header-height);
    align-items: center;
}
.copyright,
.copyright-year {
    width: 33.33%;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
}
.socials {
    width: 33.33%;
    display: flex;
    justify-content: space-around;

    &-icon {
        cursor: pointer;
        font-size: 2.2rem;
    }
}
