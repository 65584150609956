@mixin mobile {
    @media only screen and(max-width: 768px) {
        @content;
    }
}
.container {
    min-height: 100vh;
    padding: var(--default-layout-header-height);
    @include mobile {
        padding: 0;
    }
    .heading {
        text-align: center;
    }
    .projects {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
        grid-gap: 36px;
        margin: 20px;
        @include mobile {
            grid-template-columns: 1fr;
        }
        &-item {
            word-wrap: break-word;
            background-clip: border-box;
            border: 1px solid var(--border-color);
            border-radius: 0.25rem;
            display: flex;
            flex-direction: column;
            position: relative;
            background-color: transparent;
            box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.8);
            height: 100%;
            opacity: 0.9;
            transition: all 0.5s ease 0s;
            padding: 20px;
            img {
                width: 100%;
                height: auto;
                flex-shrink: 0;
                aspect-ratio: 16/9;
                border-radius: 12px;
                object-fit: cover;
            }
            &:hover {
                box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561);
                overflow: hidden;
                -webkit-transform: scale(1.02);
                transform: scale(1.02);
            }
        }
        &-content {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
        }
        &-description {
            padding: 20px;
            .title {
                text-align: center;
            }
            .subtitle {
                font-weight: 400;
            }
        }
        &-actions {
            display: flex;
            justify-content: space-around;

            a {
                display: flex;
                padding: 8px;
                border: 2px solid var(--border-color);
                line-height: 1.4em;
                color: var(--text-color);
                transition: color 0.3s, border 0.3s;
                text-decoration: none;
                background: transparent;
                font-size: 1.6rem;
                justify-content: center;
                align-items: center;

                .icon-btn {
                    margin: 0 4px;
                }
                &:hover {
                    transform: translateY(-1px);
                    background-color: #a24dd386;
                    border-color: #a24dd386;
                }
            }
        }
    }
}
