.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding: var(--default-layout-header-height);
    .download-btn {
        margin: 20px 0;
        a {
            display: flex;
            padding: 8px;
            border: 2px solid var(--border-color);
            line-height: 1.4em;
            color: var(--text-color);
            transition: color 0.3s, border 0.3s;
            text-decoration: none;
            backdrop-filter: blur(20px);
            background: transparent;
            font-size: 1.6rem;
            justify-content: center;
            align-items: center;
            &:hover {
                transform: translateY(-1px);
                background-color: #a24dd386;
                border-color: #a24dd386;
            }
        }
    }
    .resume {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
