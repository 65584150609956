@mixin mobile {
    @media only screen and(max-width: 768px) {
        @content;
    }
}

.wrapper {
    display: grid;
    grid-gap: 36px;
    min-height: 100vh;
    padding: 120px var(--default-layout-header-height) var(--default-layout-header-height);
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    background-image: var(--image-gradient), url(../../assets/images/bg_home.jpg);
    background-position: top center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    /* > Mobile */
    @include mobile {
        place-items: center;
        grid-template-columns: 1fr;
        padding: 0;
    }
}

.content {
    width: 100%;
    height: auto;
    position: relative;
    padding-left: 100px;

    @include mobile {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
    }
    .heading {
        font-size: 3.5rem;
        text-transform: uppercase;
        @include mobile {
            font-size: 2rem;
        }
        &-name {
            font-size: 4rem;
            padding-right: 45px;
            padding-bottom: 20px;
            @include mobile {
                padding-right: 0;
                font-size: 2.5rem;
            }
        }
    }
    .type-name {
        font-size: 3.5rem;
        color: var(--color-highlight);

        @include mobile {
            font-size: 2rem;
        }
    }

    .container {
        display: flex;
    }

    .home-socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: absolute;
        right: 0;
        bottom: 10px;
        @include mobile {
            right: 10px;
        }
        .icon {
            font-size: 2.2rem;
            cursor: pointer;
        }

        &::after {
            content: "";
            width: 1px;
            height: 20px;
            background-color: var(--color-highlight);
        }
    }

    .home-image {
        background: linear-gradient(var(--color-highlight), transparent);
        width: 350px;
        margin: 0 auto;
        height: 400px;
        position: relative;
        border-top-left-radius: 120px;
        border-top-right-radius: 120px;
        overflow: hidden;

        @include mobile {
            width: 300px;
            height: 350px;
        }
        img {
            width: 350px;
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            @include mobile {
                width: 300px;
            }
        }
    }
    .introduce {
        h4 {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 20px;
            @include mobile {
                font-size: 1.5rem;
            }
        }
        &-image {
            text-align: center;
            img {
                width: 100%;
                height: 100%;
                border-radius: 16px;
            }
        }
    }
}

.container {
    width: 100%;
    .heading {
        text-align: center;
        text-transform: uppercase;
        &-sub {
            text-align: center;
            font-weight: 400;
            margin-bottom: 40px;
        }
    }
}
.socials {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    @include mobile {
        margin-bottom: 80px;
    }
    &-item {
        width: 35px;
        height: 35px;
        background-color: rgb(255, 255, 255);
        border: solid blue;
        border-radius: 100px;

        transition: 0.6s;
        position: relative;

        &:hover {
            background-color: var(--color-highlight);
            border: solid var(--text-color);
            transition: 0.5s;
        }
    }
    &-icon {
        color: var(--black);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.wave {
    display: inline-block;
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    animation: wave-animation 1s 2s infinite;
}

@keyframes wave-animation {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
        transform: rotate(-8deg);
    }
    30% {
        transform: rotate(14deg);
    }
    40% {
        transform: rotate(-4deg);
    }
    50% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
        transform: rotate(0deg);
    }
}
